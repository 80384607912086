import React from "react";
import { BiSolidQuoteRight } from "react-icons/bi";
import client1 from "../assets/client1.jpeg";
import client2 from "../assets/client2.jpeg";
import client3 from "../assets/client3.jpeg";

const Testimonial = () => {
  return (
    <div className="container h-auto lg:h-[700px] flex flex-col justify-center mx-auto p-10">
      <div>
        <h1 className="capitalize text-3xl font-medium text-center pb-6">
          testimomials from afew clients
        </h1>
      </div>
      <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8 md:gap-4 p-6">
        {/* testimonial-1 */}
        <div className="md:h-[520px] lg:h-[500px] text-center ml-6 p-8 bg-slate-200 hover:bg-green-300 rounded-2xl">
          <img
            className="h-24 w-24 object-cover rounded-full inline-block border-2 border-[var(--primary)] bg-opacity-10 items-center justify-center"
            src={client1}
            alt=""
          />
          <BiSolidQuoteRight size={25} className="block text-yellow-500" />
          <p className="lg:pt-7 leading-relaxed font-light pt-3 max-w-sm mx-auto">
            I have been able to buy seedlings, fertilizer and insecticides with
            the aid of Nova Loans.My farm production has been boosted making me
            profits of up to 50%.
          </p>
          <h3 className="font-medium text-base pt-4 lg:pt-2 tracking-wider">
            Ayebare Joan
          </h3>
          <p className="text-[var(--primary)]">Farmer</p>
        </div>
        {/* testimonial-2 */}
        <div className="md:h-[520px] lg:h-[500px] text-center ml-6 p-8 bg-slate-200 hover:bg-green-300 rounded-2xl">
          <img
            className="h-24 w-24 object-cover rounded-full inline-block border-2 border-[var(--primary)] bg-opacity-10 items-center justify-center"
            src={client2}
            alt=""
          />
          <BiSolidQuoteRight size={25} className="block text-yellow-500" />
          <p className="lg:pt-7 leading-relaxed font-light pt-3 max-w-sm mx-auto">
            Through Nova Microfinance's business loans program, i have been able
            to restock my merchandise on time and import items directly from the
            supplier which saves me up to 20%.
          </p>
          <h3 className="font-medium text-base pt-4 lg:pt-2 tracking-wider">
            Namaganda Sarah
          </h3>
          <p className="text-[var(--primary)]">Business Owner</p>
        </div>
        {/* testimonial-3 */}
        <div className="md:h-[520px] lg:h-[500px] text-center ml-6 p-8 bg-slate-200 hover:bg-green-300 rounded-2xl">
          <img
            className="h-24 w-24 object-cover rounded-full inline-block border-2 border-[var(--primary)] bg-opacity-10 items-center justify-center"
            src={client3}
            alt=""
          />
          <BiSolidQuoteRight size={25} className="block text-yellow-500" />
          <p className="lg:pt-7 leading-relaxed font-light pt-3 max-w-sm mx-auto">
            I have been able to increase on my machinary and expand my work
            place all thanks to the funds i obtained through Nova Microfinance.
          </p>
          <h3 className="font-medium text-base pt-4 lg:pt-2 tracking-wider">
            Ainemugisha Stela
          </h3>
          <p className="text-[var(--primary)]">Hair Stylist</p>
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
