import React from "react";
import Hero from "../components/Hero";
import Testimonial from "../components/Testimonial";
import WhyUs from "../components/WhyUs";
import Clients from "../components/Clients";
import FAQ from "../components/FAQ";
import CTA from "../components/CTA";

const Home = () => {
  return (
    <div>
      <Hero />
      <CTA />
      <WhyUs />
      <Clients />
      <Testimonial />
      <FAQ />
    </div>
  );
};

export default Home;
