import React from "react";
import financial from '../assets/impact_02.webp'
import group from '../assets/impact_08.webp'

const ServicePage = () => {
  return (
    <div className="container mx-auto p-4">
      <section className="text-center my-20">
        <h1 className="text-3xl font-bold mb-4">Nova Microfinance Services</h1>
        <p className="text-base font-light leading-relaxed tracking-wider">
          At Nova Microfinance, we empower individuals and groups with financial
          solutions designed to foster economic growth and stability. Our
          services include financial literacy programs and group-based loans
          tailored to meet your needs.
        </p>
      </section>

      <section className="my-8 md:flex md:space-x-7">
        <div className="md:w-1/2 mb-8 md:mb-0">
          <img
            src={financial}
            alt="Financial Literacy"
            className="w-full h-auto md:h-[600px] object-cover rounded-lg"
          />
        </div>
        <div className="md:w-1/2">
          <h2 className="text-2xl font-bold mb-4">Financial Literacy</h2>
          <p className="text-base font-light leading-relaxed tracking-wider pb-3">
            Our Financial Literacy programs are designed to educate individuals
            on the fundamentals of managing finances. We cover essential topics
            such as budgeting, saving, investing, and debt management,
            empowering our clients to make informed financial decisions.
          </p>
          <p className="text-base font-light leading-relaxed tracking-wider">
            NOVA understands that people living in poverty have heterogeneous
            financial needs, and hence, it innovates and develops new products
            tailored to meet their diverse needs, as well, we provide free
            financial literacy classes to our members.
          </p>
        </div>
      </section>

      <section className="my-14 md:mt-32 md:flex md:flex-row-reverse md:space-x-reverse md:space-x-7">
        <div className="md:w-1/2 mb-8 md:mb-0">
          <img
            src={group}
            alt="Group Based Loans"
            className="w-full h-auto md:h-[600px] object-cover rounded-lg"
          />
        </div>
        <div className="md:w-1/2">
          <h2 className="text-2xl font-bold mb-4">Group Based Loans</h2>
          <p className="text-base font-light leading-relaxed tracking-wider pb-3">
            Our Group Based Loans provide financial assistance to groups of
            individuals who come together to support each other. This model
            fosters community support and shared responsibility, making it
            easier for members to access funds and repay loans collectively.
          </p>
          <p className="text-base font-light leading-relaxed tracking-wider">
            we provide women group-based collateral-free loans at 20% for a
            period of six (6) months.Although this rate is very low compared to
            our competitors and other players in the market, it is still a high
            cost of capital for these low-income informal women. We are in
            continuous pursuit of investors, partners or funders who can extend
            to us affordable credit lines to enable us significantly lower the
            lending rate to below 15%. We believe that lending rates below this
            threshold will significantly spur this informal economy.
          </p>
        </div>
      </section>
    </div>
  );
};

export default ServicePage;
