import React from "react";
import sam from '../assets/sam.webp'
import deo from '../assets/deo.webp'
import herbert from '../assets/herbert.webp'
import monica from '../assets/monica.webp'

const teamMembers = [
  {
    name: "SAM MWANJA",
    position: "HEAD OF OPERATIONS",
    image: sam,
    //image: "https://novamicrofinance.com/assets/images/sam.jpg",
    bio: "Sam is a Fellow of the Association of Certified Chartered Accountants FCCA since 2013 and a Member of the Association of Certified Chartered Accountant ACCA since 2008. He is a member of the Institute of Certified Public Accountants of Uganda ICPAU since 2009, and he holds a Master’s degree in Business Administration (MBA) from Edinburgh Business School HERIOT-WATT University, UK. He has over 18 years work experience in the financial sector in the following fields, operations, accounting & financial, treasury, audit practice, risk & compliance, credit, tax, funding, among others.",
  },
  {
    name: "DEO VIANNEY KAKEETO",
    position: "HEAD OF INTERNAL AUDIT",
    image: deo,
    //image: "https://novamicrofinance.com/assets/images/deo.png",
    bio: "Deo is a highly skilled finance professional with over 12 years of work experience and comes with extensive experience in financial management, budgeting, planning and analysis, audit, administrative & risk management, and compliance. He holds a Bachelor of Commerce degree in Accounting from Makerere University Business School, is a Certified Public Accountant (CPA) and a Fellow of the Association of Chartered Certified Accountants (UK) (FCCA). He has also finalized his Masters in Business Administration (MBA) from the Edinburgh Business School of Heriot-Watt University, Scotland, UK.",
  },
  {
    name: "HERBERT TWESIGYE",
    position: "HEAD OF BUSINESS",
    image: herbert,
    //image: "https://novamicrofinance.com/assets/images/herbert.jpeg",
    bio: "Herbert is currently working as Head of Business NOVA Microfinance Limited since April 2024. He is responsible for the overall business development, sales and marketing functions of NOVA. He provides leadership to the branch managers and loans officers across all the NOVA branch network. Prior to this promotion, Herbert had served as the Regional Manager at NOVA Microfinance in charge of the Eastern region branches of Jinja and Iganga supervising 12 staff. Prior to this role, Herbert served as the Business Manager who was instrumental in the establishment of the NOVA business, including setting up of the preliminary systems. He has spearheaded the growth of the business from zero (0) clients to now over 3,000 clients with an outstanding portfolio of over UGX 1,200,000,000.",
  },
  {
    name: "NANTALE MONICA",
    position: "HUMAN RESOURCE MANAGER",
    image: monica,
    //image: "https://novamicrofinance.com/assets/images/monica.jpeg",
    bio: "Monica currently works as the HR Manager at NOVA Microfinance since January 2023. She has been instrumental in establishing the human resources policy manual and ensuring implementation. She has been central in the recruitment process of most of NOVA Microfinance human resource. Prior to this role, she supported the administration role which involved planning for team engagements and team building activities, submission of returns and all statutory payments of NSSF, PAYE, WHT, among others and ensuring compliance with the regulatory requirements.",
  },
];

function App() {
  return (
    <div className="min-h-screen bg-white flex items-center justify-center p-4">
      <div className="max-w-4xl mx-auto text-center pb-8">
        <h1 className="text-3xl font-bold mb-8">Meet Our Team</h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-8">
          {teamMembers.map((member, index) => (
            <div
              key={index}
              className="bg-slate-200 hover:bg-green-300 rounded-lg shadow-lg p-6 flex flex-col items-center"
            >
              <img
                className="w-52 h-52 object-cover rounded-full mb-4 border-2 border-[var(--primary)]"
                src={member.image}
                alt={member.name}
              />
              <h2 className="text-base font-semibold mb-2">{member.name}</h2>
              <h3 className="text-gray-600 mb-4">{member.position}</h3>
              <p className="text-gray-700 text-sm">{member.bio}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default App;
