import React from "react";
import { FaAngleUp } from "react-icons/fa";
import ScrollToTop from "react-scroll-up";

const ScrollToTopButton = () => {
  return (
    <div className="relative z-[300]">
      <ScrollToTop showUnder={160}>
        <div className="font-bold cursor-pointer h-10 flex items-center justify-center w-8 rounded-md bg-[var(--primary)] text-white">
          <FaAngleUp size={20} />
        </div>
      </ScrollToTop>
    </div>
  );
};

export default ScrollToTopButton;
