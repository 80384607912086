import React from "react";
import { motion } from "framer-motion";
import { fadeIn } from "../variants";
import hero_image from '../assets/about_us.jpg'

const Hero = () => {
  return (
    <div className="h-[500px] md:h-[660px] relative">
      <div>
        <div>
          <img className="w-full object-cover h-[660px]" src={hero_image} alt="" />
        </div>
        <div className="absolute top-20 md:top-44 w-full">
        <motion.div
          variants={fadeIn("down", 0.2)}
          initial="hidden"
          whileInView={"show"}
          viewport={{ once: false, amount: 0.7 }}
          className="container text-center pt-5 md:pt-0 px-4 text-white"
        >
          <h1 className="text-4xl md:text-6xl font-bold mb-6">
            Empowering Your Financial Future with Nova Microfinance
          </h1>
          <p className="text-lg md:text-2xl font-normal mb-10">
            At Nova Microfinance, we offer personal and group loans to help you
            achieve your financial goals. Whether you need funds for personal
            expenses or want to start a business, we have the right loan options
            for you.
          </p>
        </motion.div>
        </div>
      </div>
    </div>
  );
};

export default Hero;

    <div
      className="h-[500px] md:h-[660px] flex items-center justify-center bg-cover bg-center"
      style={{
        backgroundImage:
          "url()",
      }}
    >
      <div className="bg-black bg-opacity-50 w-full h-full flex items-center justify-center px-4">
        <motion.div
          variants={fadeIn("down", 0.2)}
          initial="hidden"
          whileInView={"show"}
          viewport={{ once: false, amount: 0.7 }}
          className="container text-center pt-5 md:pt-0 text-white"
        >
          <h1 className="text-4xl md:text-6xl font-bold mb-6">
            Empowering Your Financial Future with Nova Microfinance
          </h1>
          <p className="text-lg md:text-2xl font-normal mb-10">
            At Nova Microfinance, we offer personal and group loans to help you
            achieve your financial goals. Whether you need funds for personal
            expenses or want to start a business, we have the right loan options
            for you.
          </p>
        </motion.div>
      </div>
    </div>