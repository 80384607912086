import React from "react";
import aboutLogo from '../assets/page-heading-bg.jpg'
//import story from '../assets/brief-intro.png'
import impact_9 from '../assets/impact_09.webp'
import { MdOutlineVisibility } from "react-icons/md";
import { BiBullseye } from "react-icons/bi";
import { FaRegFlag } from "react-icons/fa";
import { TbBuildingCommunity } from "react-icons/tb";
import { GrGrow } from "react-icons/gr";
import { motion } from "framer-motion";
import { fadeIn } from "../variants";

const About = () => {
  return (
    <div className="h-auto w-full relative">
      <img
        className="h-[400px] w-full object-cover opacity-90 overflow-hidden"
        src= {aboutLogo}
        alt="about-logo"
      />
      <motion.div
        variants={fadeIn("up", 0.3)}
        initial="hidden"
        whileInView={"show"}
        viewport={{ once: false, amount: 0.7 }}
        className="absolute flex flex-col items-center justify-center top-6 lg:top-24 pt-4 md:pt-0  px-4 md:px-0"
      >
        <h1 className="text-4xl font-medium text-center text-white">
          About
          <span className="text-[var(--primary)] pl-6 font-light">
            Nova Microfinance Ltd
          </span>
        </h1>

        <p className="text-center text-white font-light tracking-wider pb-4 text-lg  pt-4 md:pt-10 md:px-36 md:leading-relaxed">
          NOVA Microfinance is a financial institution incorporated in Uganda
          under section 18(3) of the Companies Act 2012 with Company
          registration number 80020003414219. NOVA Microfinance is regulated
          under the Tier 4 Microfinance Institutions and Money Lenders Act of
          2016, and licensed by the Uganda Microfinance Regulatory Authority
          (UMRA) under license number 933 NDT.
        </p>
      </motion.div>

      {/* Our story section */}
      <div className="bg-slate-200 h-auto lg:h-[700px] lg:flex lg:items-center">
        <div className="container grid md:grid-cols-1 lg:grid-cols-2 md:place-items-center px-4 pt-6 md:py-0 gap-8">
          <div className="md:py-4">
            <h2 className="text-center font-medium text-3xl md:pb-6">
              Our Story
            </h2>
            <p className="pt-4 font-light tracking-wider text-base leading-relaxed">
              We are in the business of providing affordable, hustle and
              collateral free credit to the low income earners in the informal
              sector. NOVA’s interventions aim to achieve large scale, positive
              changes through economic and social programmes that enable women
              and men to realize their potential. The goal of NOVA is to reduce
              poverty and empower women and the marginalized people within the
              community by creating economic opportunities through microfinance
              and livelihood support, employment, and increasing access to
              education and health services. NOVA firmly believes and is
              actively involved in promoting human rights, dignity and gender
              equity through poor people’s social, economic, and human capacity
              building. NOVA is committed to making its programs socially,
              financially and environmentally sustainable, using new methods and
              improved technologies.
            </p>
          </div>
          <div className="pt-5 pb-5 md:pt-0">
            <img
              className="rounded-lg w-[700px] h-[600px] object-cover"
              src={impact_9}
              alt=""
            />
          </div>
        </div>
      </div>

      {/* Who we are section */}
      <div className="grid place-content-center h-auto pb-8 pt-8">
        <div className="container px-4 md:pt-0">
          <h2 className="text-center font-medium text-3xl">Who we are</h2>
          <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-2 md:mt-8">
            {/* card-1 */}
            <div className="min-w-xl flex items-center justify-between px-6 h-[300px] md:h-[200px] shadow-md rounded-lg bg-slate-200 hover:bg-green-300">
              <div className="rounded-full flex items-center p-3 bg-[var(--primary)]">
                <MdOutlineVisibility
                  size={25}
                  className="text-[var(--tertairy)]"
                />
              </div>
              <div className="flex-grow pl-4 md:pl-7">
                <h3 className="text-lg font-medium capitalize">our vision</h3>
                <p className="font-light text-gray-400 text-base leading-8 tracking-wider">
                  To be the preferred and leading Microfinancing Partner in
                  Africa.
                </p>
              </div>
            </div>
            {/* card-2 */}
            <div className="min-w-xl flex items-center justify-between px-6 h-[300px] md:h-[200px] shadow-md rounded-lg bg-slate-200 hover:bg-green-300">
              <div className="rounded-full flex items-center p-3 bg-[var(--primary)]">
                <BiBullseye size={25} className="text-[var(--tertairy)]" />
              </div>
              <div className="flex-grow pl-4 md:pl-7">
                <h3 className="text-lg font-medium capitalize">our mission</h3>
                <p className="font-light text-gray-400 text-base leading-8 tracking-wider">
                  To empower the low income earners to realize their
                  entrepreneurial potential through affordable microfinancing
                  and financial inclusion.
                </p>
              </div>
            </div>
            {/* card-3 */}
            <div className="min-w-xl flex items-center justify-between px-6 h-[300px] md:h-[200px] shadow-md rounded-lg bg-slate-200 hover:bg-green-300">
              <div className="rounded-full flex items-center p-3 bg-[var(--primary)]">
                <FaRegFlag size={25} className="text-[var(--tertairy)]" />
              </div>
              <div className="flex-grow pl-4 md:pl-7">
                <h3 className="text-lg font-medium capitalize">
                  our core values
                </h3>
                <p className="font-light text-gray-400 text-base leading-8 tracking-wider">
                  Integrity ,Trust ,Community Development,Affordability, Dynamic
                </p>
              </div>
            </div>
            {/* card-4 */}
            <div className="min-w-xl flex items-center justify-between px-6 h-[300px] md:h-[200px] shadow-md rounded-lg bg-slate-200 hover:bg-green-300">
              <div className="rounded-full flex items-center p-3 bg-[var(--primary)]">
                <TbBuildingCommunity
                  size={25}
                  className="text-[var(--tertairy)]"
                />
              </div>
              <div className="flex-grow pl-4 md:pl-7">
                <h3 className="text-lg font-medium capitalize">our purpose</h3>
                <p className="font-light text-gray-400 text-base leading-8 tracking-wider">
                  In Business to Improve Livelihoods.
                </p>
              </div>
            </div>
            {/* card-5 */}
            <div className="min-w-xl flex items-center justify-between px-6 h-[300px] md:h-[200px] shadow-md rounded-lg bg-slate-200 hover:bg-green-300">
              <div className="rounded-full flex items-center p-3 bg-[var(--primary)]">
                <GrGrow size={25} className="text-[var(--tertairy)]" />
              </div>
              <div className="flex-grow pl-4 md:pl-7">
                <h3 className="text-lg font-medium capitalize">our tagline</h3>
                <p className="font-light text-gray-400 text-base leading-8 tracking-wider">
                  Growing Together.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
