import React from "react";
import Accordion from "./Accordion";

const FAQ = () => {
  return (
    <div className="container h-auto md:h-[600px]">
      <div>
        <h1 className="font-medium text-center text-3xl tracking-wider pb-10">
          Frequently Asked Questions
        </h1>

        <div>
          <Accordion
            title="What is the eligibility criteria for applying for a group-based loan?"
            answer="All group members must be 18 years or older and have a stable source of income."
          />
          <Accordion
            title="How can I enroll in the financial literacy program?"
            answer=" Enrolling in our financial literacy program is simple. You can sign up through our website by filling out the registration form, or you can visit our office and speak with one of our representatives."
          />
          <Accordion
            title="What are the interest rates and repayment terms for group-based loans?"
            answer=" Interest rates and repayment terms for group-based loans vary based on the loan amount, duration, and the group's creditworthiness. We offer competitive rates and flexible repayment schedules to ensure that the loan is manageable for all members. "
          />
          <Accordion
            title="Can individuals with no prior credit history apply for a loan?"
            answer=" Yes, individuals with no prior credit history can apply for a group-based loan. Our program is designed to provide financial access to those who may not qualify for traditional loans. The collective responsibility of the group helps mitigate the risk and supports members in building their credit history."
          />
          <Accordion
            title="What topics are covered in the financial literacy workshops?"
            answer="Our financial literacy workshops cover a wide range of topics, including budgeting and saving, debt management, investment basics, understanding credit scores, and financial planning. These workshops are designed to provide you with practical knowledge and skills to manage your finances effectively."
          />
          <Accordion
            title="How does the group-based loan process work?"
            answer="Each group member is jointly responsible for the loan repayment, fostering a sense of community and mutual support."
          />
        </div>
      </div>
    </div>
  );
};

export default FAQ;
