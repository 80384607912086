import React from "react";
import contactImage from "../assets/contact-nova.jpg";
import { FaPhoneAlt } from "react-icons/fa";
import { MdOutlineEmail } from "react-icons/md";
import { FaLocationDot } from "react-icons/fa6";
import Button from "../components/Button";

const Contact = () => {
  return (
    <div className="h-auto w-full relative">
      <img
        className="h-[400px] w-full object-cover opacity-96"
        src={contactImage}
        alt=""
      />
      <div className="container px-4">
        <div className="container top-36 flex flex-col text-center items-center justify-center mx-auto absolute text-white">
          <h1 className="text-2xl md:text-4xl font-medium">
            Contact <span className="font-light">Nova Microfinance</span>
          </h1>
          <p className="font-extralight text-xl md:text-2xl mt-4">
            Feel Free to contact Nova Microfinance Ltd
          </p>
        </div>

        {/* contact section */}
        <div className="grid grid-cols-1 md:grid-cols-2 items-center pt-8">
          <div className="pb-3 px-4 flex flex-col gap-5">
            <h2 className="text-xl md:text-3xl p-8">
              Send us a <span className="text-[var(--primary)]">message</span>
            </h2>
            {/* card1 */}
            <div className="max-w-sm flex items-center justify-between px-6 py-8 shadow-md rounded-lg">
              <div className="rounded-full flex items-center p-3 bg-[var(--primary)]">
                <FaPhoneAlt size={25} className="text-[var(--tertairy)]" />
              </div>
              <div className="flex-grow pl-10">
                <h3 className="text-lg font-medium capitalize">phone number</h3>
                <p className="font-light text-base tracking-wider">
                  07033553090
                </p>
              </div>
            </div>
            {/* card2 */}
            <div className="max-w-sm flex items-center justify-between px-6 py-8 shadow-md rounded-lg">
              <div className="rounded-full flex items-center p-3 bg-[var(--primary)]">
                <MdOutlineEmail size={25} className="text-[var(--tertairy)]" />
              </div>
              <div className="flex-grow pl-10">
                <h3 className="text-lg font-medium capitalize">email</h3>
                <p className="font-light text-base tracking-wider">
                  info@novamicrofinance.com
                </p>
              </div>
            </div>
            {/* card3 */}
            <div className="max-w-sm flex items-center justify-between px-6 py-8 shadow-md rounded-lg">
              <div className="rounded-full flex items-center p-3 bg-[var(--primary)]">
                <FaLocationDot size={25} className="text-[var(--tertairy)]" />
              </div>
              <div className="flex-grow pl-10">
                <h3 className="text-lg font-medium capitalize">location</h3>
                <p className="font-light text-base capitalize tracking-wider">
                  Biira Business Complex, opp shell busega
                </p>
              </div>
            </div>
          </div>
          {/* form */}
          <div className="pb-12 pt-4 px-4 ">
            <div className="h-auto p-6 md:p-12 rounded-md shadow-sm bg-[var(--tertairy)]">
              <form action="https://getform.io/f/bjjeeggb" method="POST" className="grid grid-cols-1 gap-4">
                <div>
                  <label
                    htmlFor=""
                    className="text-base md:text-lg text-white font-light"
                  >
                    Enter Name
                  </label>
                  <input
                    type="text"
                    name="name"
                    placeholder="Enter your name"
                    className="ring-1 ring-white w-full rounded-sm p-4 mt-4 focus:ring-2 focus:ring-teal-300 outline-none"
                  />
                </div>

                <div className="pt-6">
                  <label
                    htmlFor=""
                    className="text-base md:text-lg text-white font-light"
                  >
                    Email Address
                  </label>
                  <input
                    type="email"
                    name="email"
                    id=""
                    placeholder="Enter email address"
                    className="ring-1 ring-white w-full rounded-sm p-4 mt-4 focus:ring-2 focus:ring-teal-300 outline-none"
                  />
                </div>

                <div className="pt-6">
                  <label
                    htmlFor=""
                    className="text-base md:text-lg text-white font-light"
                  >
                    Message
                  </label>
                  <textarea
                    rows={6}
                    type="text"
                    name="message"
                    id=""
                    placeholder="Message..."
                    className="ring-1 ring-white w-full rounded-sm p-4 mt-4 focus:ring-2 focus:ring-teal-300 outline-none"
                  />
                </div>

                <div className="text-center md:pb-3"></div>
                <Button type="submit">Send Message</Button>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* map */}
      <div className="md:pt-10">
        <h2 className="capitalize text-center p-8 text-2xl md:text-3xl">
          find us on google maps
        </h2>
        <iframe
          width="100%"
          height="600"
          frameborder="0"
          scrolling="no"
          marginheight="0"
          marginwidth="0"
          src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=+(biira%20business%20complex)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
        >
          <a href="https://www.gps.ie/">gps devices</a>
        </iframe>
      </div>
    </div>
  );
};

export default Contact;
