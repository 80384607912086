import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import Dropdown from "./Dropdown";

const Navbar = () => {
  const [open, setOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <div className="bg-[var(--tertairy)] text-white w-full">
      <div className="container flex items-center font-medium justify-between py-5 px-4 shadow-sm">
        {/* logo */}
        <div className="z-50 md:z-0">
          <NavLink to="/">
            <h3 className="cursor-pointer uppercase text-[var(--primary)] md:text-lg tracking-wider">
              nova microfinance
            </h3>
          </NavLink>
        </div>

        {/* nav links */}
        <ul
          onClick={toggleDropdown}
          className="uppercase items-center text-sm gap-8 hidden md:flex"
        >
          <NavLink to="/" className="hover:text-[var(--primary)]">
            home
          </NavLink>

          {/* dropdown menu */}
          <Dropdown />

          <NavLink to="/services" className="hover:text-[var(--primary)]">
            services
          </NavLink>
          <NavLink to="/impact" className="hover:text-[var(--primary)]">
            impact
          </NavLink>
          <NavLink to="/contact" className="hover:text-[var(--primary)]">
            contact
          </NavLink>
        </ul>

        {/* mobile view icons */}
        <div
          onClick={() => {
            setOpen(!open);
          }}
          className="md:hidden z-50 cursor-pointer"
        >
          <ion-icon size='large' name={`${open ? "close" : "menu"}`}></ion-icon>
        </div>

        {/* mobile view */}
        <ul
          className={`uppercase text-sm flex flex-col justify-center items-center md:hidden absolute bg-[var(--tertairy)] gap-16 w-full md:w-auto h-[480px] z-10 bottom-0 py-24 pl-4 duration-500 ${
            open
              ? "left-0 top-20 pt-10 grid place-items-center"
              : "left-0 top-[-490px]"
          }`}
        >
          <NavLink to="/" className="hover:text-[var(--primary)]">
            home
          </NavLink>

          {/* dropdown menu */}
          <Dropdown />

          <NavLink to="/services" className="hover:text-[var(--primary)]">
            services
          </NavLink>
          <NavLink to="/impact" className="hover:text-[var(--primary)]">
            impact
          </NavLink>
          <NavLink to="/contact" className="hover:text-[var(--primary)]">
            contact
          </NavLink>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
