import React from "react";
import { FaBalanceScale } from "react-icons/fa";
import { GiAchievement, GiTeacher } from "react-icons/gi";
import { motion } from "framer-motion";
import { fadeIn } from "../variants";

const WhyUs = () => {
  return (
    <div className="container h-auto lg:h-[800px] flex flex-col justify-center">
      <div className="px-4 md:px-0 pt-12 md:pt-0 ">
        <h1 className="text-center text-3xl capitalize">why choose us?</h1>
        <p className="capitalize tracking-wider text-center text-xl font-light text-gray-400 pt-6 leading-relaxed">
          our solutions for business growth
        </p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 place-items-center px-4 pt-8 pb-8 gap-6 overflow-hidden">
        <motion.div
          variants={fadeIn("up", 0.3)}
          initial="hidden"
          whileInView={"show"}
          viewport={{ once: false, amount: 0.7 }}
          className="border-2 rounded-2xl w-full h-auto md:h-[440px] grid place-items-center p-8 mx-auto bg-slate-200 hover:bg-green-300"
        >
          <GiTeacher className="text-[var(--primary)]" size={45} />
          <div className="grid place-items-center pt-6 text-center">
            <h2 className="text-xl capitalize font-medium">
              Comprehensive Financial Education
            </h2>
            <p className="pt-3 leading-loose font-light max-w-md mx-auto">
              Our Financial Literacy programs offer tailored workshops and
              one-on-one coaching to help you manage your finances effectively.
              Learn budgeting, saving, debt management, and more from
              experienced professionals.
            </p>
          </div>
        </motion.div>
        <motion.div
          variants={fadeIn("down", 0.3)}
          initial="hidden"
          whileInView={"show"}
          viewport={{ once: false, amount: 0.7 }}
          className="border-2 rounded-2xl w-full h-auto md:h-[440px] grid place-items-center p-8 mx-auto bg-slate-200 hover:bg-green-300"
        >
          {" "}
          <GiAchievement className="text-[var(--primary)]" size={50} />
          <div className="grid place-items-center pt-6 text-center">
            <h2 className="text-xl capitalize font-medium">
              Accessible Group-Based Loans
            </h2>
            <p className="pt-3 leading-loose font-light max-w-md mx-auto">
              We provide group-based loans designed to offer financial access to
              those who may not qualify for traditional loans. Benefit from
              flexible terms and collective responsibility, ensuring manageable
              repayment plans.
            </p>
          </div>
        </motion.div>
        <motion.div
          variants={fadeIn("up", 0.3)}
          initial="hidden"
          whileInView={"show"}
          viewport={{ once: false, amount: 0.7 }}
          className="border-2 rounded-2xl w-full h-auto md:h-[440px] grid place-items-center p-8 mx-auto bg-slate-200 hover:bg-green-300"
        >
          <FaBalanceScale className="text-[var(--primary)]" size={45} />
          <div className="grid place-items-center pt-6 text-center">
            <h2 className="text-xl capitalize font-medium">
              Competitive Interest Rates
            </h2>
            <p className="pt-3 leading-loose font-light max-w-md mx-auto">
              We offer competitive interest rates and transparent terms,
              ensuring our clients can access the financial support they need
              without unnecessary financial burden at affordable and transparent
              costs.
            </p>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default WhyUs;
