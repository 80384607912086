import React from "react";
import CountUp from "react-countup";

const Clients = () => {
  return (
    <div className="bg-slate-100 h-auto w-full">
      <div className="container h-auto md:h-[400px] pt-6 px-4 pb-6 gap-8 md:gap-0 mx-auto grid grid-cols-2 md:grid-cols-3 lg:flex justify-between items-center text-center">
        <div>
          <p className="text-2xl tracking-wider">
            <CountUp start={0} end={5256} duration={4} />
          </p>
          <p className="pt-3 capitalize font-light tracking-wider text-xl">
            active members
          </p>
        </div>
        <div>
          <p className="text-2xl tracking-wider">
            <CountUp start={0} end={2448} duration={4} />
          </p>
          <p className="pt-3 capitalize font-light tracking-wider text-xl">
            active loans
          </p>
        </div>
        <div>
          <p className="text-2xl tracking-wider">
            <CountUp start={0} end={286} duration={4} />
          </p>
          <p className="pt-3 capitalize font-light tracking-wider text-xl">
            member groups
          </p>
        </div>
        <div>
          <p className="text-2xl tracking-wider">
            <CountUp start={0} end={3} duration={4} />
          </p>
          <p className="pt-3 capitalize font-light tracking-wider text-xl">
            active branches
          </p>
        </div>
        <div>
          <p className="text-2xl tracking-wider">
            <CountUp start={0} end={1200000000} duration={4} />
          </p>
          <p className="pt-3 capitalize font-light tracking-wider text-xl">
            loan portfolio
          </p>
        </div>
      </div>
    </div>
  );
};

export default Clients;
