import { NavLink } from "react-router-dom";
import Button from "../components/Button";
import React from "react";

const CTA = () => {
  return (
    <div className="w-full h-auto md:h-[360px] grid">
      <div className="container h-auto  px-4 md:px-0 flex flex-col items-center justify-center">
        <h1 className="font-medium pt-4 md:pt-0 text-3xl text-center leading-relaxed tracking-wider">
          Easy Loan Application Process for You
        </h1>
        <p className="text-center font-light tracking-wider pb-4 text-lg md:text-xl pt-6 md:px-20 md:leading-[30px]">
          Applying for a loan with Nova Microfinance is quick and hassle-free.
          Our three-step process ensures a seamless experience from start to
          finish.
        </p>
        <div className="pt-4">
          <NavLink to="/contact">
            <Button>More Inquiries</Button>
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default CTA;
