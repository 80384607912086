import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { IoChevronDownOutline } from "react-icons/io5";

const Dropdown = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };
  return (
    <div className="relative flex flex-col items-center">
      <li className="relative">
        <div className=" flex items-center gap-1">
          <button
            onClick={toggleDropdown}
            className="cursor-pointer focus:outline-none hover:text-[var(--primary)]"
          >
            ABOUT
          </button>
          <IoChevronDownOutline size={15} />
        </div>
        {dropdownOpen && (
          <ul className="absolute top-14 left-0 mt-2 w-52 bg-[var(--tertairy)] shadow-lg rounded-md overflow-hidden z-20">
            <li className="px-4 py-4 cursor-pointer hover:text-[var(--primary)] hover:bg-slate-700">
              <NavLink
                to="/about"
                onClick={toggleDropdown}
                className={"hover:text-[var(--secondary)]"}
              >
                About Nova
              </NavLink>
            </li>
            <li className="px-4 py-4 cursor-pointer hover:text-[var(--primary)] hover:bg-slate-700">
              <NavLink
                to="/ourteam"
                onClick={toggleDropdown}
                className={"hover:text-[var(--secondary)]"}
              >
                Our Team
              </NavLink>
            </li>
          </ul>
        )}
      </li>
    </div>
  );
};

export default Dropdown;
