// src/pages/ImpactsPage.jsx

import React from "react";
import Button from "../components/Button";
import { NavLink } from "react-router-dom";

const ImpactsPage = () => {
  return (
    <div className="bg-gray-100 h-auto flex flex-col justify-center text-gray-800">
      {/* Header */}
      <header className="py-8 mt-8 md:mt-0">
        <div className="container mx-auto text-center">
          <h1 className="text-3xl pt-8 font-bold">Our Impacts</h1>
          <p className="mt-2">How Nova Microfinance is making a difference</p>
        </div>
      </header>

      {/* Financial Impacts */}
      <section className="container mx-auto py-14 px-4">
        <h2 className="text-2xl font-semibold text-center">
          Financial Impacts
        </h2>
        <div className="grid md:grid-cols-3 gap-8 mt-8">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h3 className="text-xl font-bold">Increased Income</h3>
            <p className="mt-2 font-light">
              Our clients have seen an average increase of 20% in their incomes
              through our microloans and financial training programs.
            </p>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h3 className="text-xl font-bold">Job Creation</h3>
            <p className="mt-2 font-light">
              We have helped create over 10,000 jobs in local communities by
              funding small businesses and entrepreneurial ventures.
            </p>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h3 className="text-xl font-bold">Financial Inclusion</h3>
            <p className="mt-2 font-light">
              Our services have brought financial inclusion to over 50,000
              individuals who previously lacked access to banking.
            </p>
          </div>
        </div>
      </section>

      {/* Social Impacts */}
      <section className="bg-gray-200 py-14">
        <div className="container mx-auto px-4">
          <h2 className="text-2xl font-semibold text-center">Social Impacts</h2>
          <div className="grid md:grid-cols-3 gap-8 mt-8">
            <div className="bg-white p-6 rounded-lg shadow-lg">
              <h3 className="text-xl font-bold">Education Access</h3>
              <p className="mt-2 font-light">
                We provide basic financial education & training to equip our
                clients with the knowledge to run their businesses, outgrow the
                recurrent debt cycle and avoid multiple loans, among other
                aspects like budgeting and saving.
              </p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-lg">
              <h3 className="text-xl font-bold">Women's Empowerment</h3>
              <p className="mt-2 font-light">
                60% of our loan recipients are women, fostering gender equality
                and empowering women in their communities.
              </p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-lg">
              <h3 className="text-xl font-bold">Community Development</h3>
              <p className="mt-2 font-light">
                We support community projects that improve local infrastructure,
                healthcare, and social services.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Environmental Impacts */}
      <section className="container mx-auto py-14 px-4">
        <h2 className="text-2xl font-semibold text-center">
          Environmental Impacts
        </h2>
        <div className="grid md:grid-cols-3 gap-8 mt-8">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h3 className="text-xl font-bold">Green Loans</h3>
            <p className="mt-2 font-light">
              We provide loans for eco-friendly businesses and sustainable
              agricultural practices, promoting environmental stewardship.
            </p>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h3 className="text-xl font-bold">Energy Access</h3>
            <p className="mt-2 font-light">
              Our programs have facilitated access to clean energy solutions
              like solar power for over 20,000 households.
            </p>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h3 className="text-xl font-bold">Reforestation Projects</h3>
            <p className="mt-2 font-light">
              We actively support reforestation projects that have planted over
              100,000 trees, restoring natural habitats and combating climate
              change.
            </p>
          </div>
        </div>
      </section>

      {/* Call to Action */}
      <section className="py-14">
        <div className="container mx-auto text-center">
          <h2 className="text-2xl font-bold">Join Us in Making a Difference</h2>
          <p className="mt-4 pb-4">
            Support our mission to empower communities through financial
            services, social initiatives, and environmental sustainability.
          </p>
          <NavLink to="/contact">
            <Button>Get Involved</Button>
          </NavLink>
        </div>
      </section>
    </div>
  );
};

export default ImpactsPage;
