// src/components/Footer.jsx

import React from "react";
import {
  FaFacebookF,
  FaTwitter,
  FaLinkedinIn,
  FaInstagram,
} from "react-icons/fa";
import { NavLink } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="bg-[var(--tertairy)] text-white py-10">
      <div className="container mx-auto px-4 grid lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-1 gap-10">
        {/* Company Info */}
        <div>
          <NavLink to="/" className="text-xl font-bold">
            Nova Microfinance
          </NavLink>
          <p className="mt-4 font-light leading-loose">
            Empowering communities through sustainable financial services,
            social initiatives, and environmental sustainability.
          </p>
        </div>

        {/* Quick Links */}
        <div>
          <h4 className="text-xl font-semibold">Quick Links</h4>
          <ul className="mt-4 font-light leading-relaxed flex flex-col gap-4">
            <NavLink className="mt-2 hover:text-[var(--primary)]" to="/about">
              About Us
            </NavLink>
            <NavLink
              className="mt-2 hover:text-[var(--primary)]"
              to="/services"
            >
              Services
            </NavLink>
            <NavLink className="mt-2 hover:text-[var(--primary)]" to="/impact">
              Our Impact
            </NavLink>
            <NavLink className="mt-2 hover:text-[var(--primary)]" to="/contact">
              Contact Us
            </NavLink>
          </ul>
        </div>

        {/* Contact Info */}
        <div>
          <h4 className="text-xl font-semibold">Contact Us</h4>
          <ul className="mt-4 font-light leading-relaxed flex flex-col gap-4">
            <li className="mt-2">Biira Complex</li>
            <li className="mt-2">Opposite Shell Busega</li>
            <li className="mt-2">Phone: 0703 353090</li>
            <li className="mt-2">Email: info@novamicrofinance.com</li>
          </ul>
        </div>

        {/* Social Media */}
        <div>
          <h4 className="text-xl font-semibold">Follow Us</h4>
          <div className="flex mt-4 space-x-4">
            <a
              href="https://facebook.com"
              className="text-white hover:text-[var(--primary)] cursor-pointer"
            >
              <FaFacebookF size={23} />
            </a>
            <a
              href="https://twitter.com"
              className="text-white hover:text-[var(--primary)] cursor-pointer"
            >
              <FaTwitter size={24} />
            </a>
            <a
              href="https://linkedin.com"
              className="text-white hover:text-[var(--primary)] cursor-pointer"
            >
              <FaLinkedinIn size={24} />
            </a>
            <a
              href="https://instagram.com"
              className="text-white hover:text-[var(--primary)] cursor-pointer"
            >
              <FaInstagram size={24} />
            </a>
          </div>
        </div>
      </div>
      <div className="container mx-auto px-4 mt-14 text-center text-sm">
        &copy; {new Date().getFullYear()} Nova Microfinance. All rights
        reserved.
      </div>
    </footer>
  );
};

export default Footer;
